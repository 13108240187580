import axios from 'axios';
import { notify } from '@kyvg/vue3-notification';
import store from '@/store';

const instance = axios.create({
  // baseURL: 'http://localhost:8080/',
  baseURL: 'https://iron-man.owiwi.co.uk/dmsat/',
});

instance.defaults.headers.post['Content-Type'] = 'application/json';
instance.defaults.headers.get.accept = 'application/json';

instance.throwError = (error) => Promise.reject(parseError(error));

instance.handleError = (error) => parseError(error);

instance.errorNotify = (error) => {
  notify({
    title: 'Υπήρξε κάποιο πρόβλημα!',
    text: parseError(error),
    type: 'error',
  });
};

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 400) {
      return Promise.reject(error);
    }
    if (error.response.status === 401) {
      store.dispatch('logout');
    }
    return Promise.reject(error);
  }
);

const parseError = (error) => {
  let errorText = '';

  // The server response was received with a status code that falls out of the range of 2xx
  // OR
  // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of
  if (error.response || error.request) {
    const mode = error.response ? 'response' : 'request';

    if (!error[mode].data) {
      errorText = error[mode].statusText;
    } else if (typeof error[mode].data === 'string') {
      errorText = error[mode].data;
      if (errorText === 'INVALID_CREDENTIALS') {
        errorText =
          'Δεν υπάρχει λογαριασμός με αυτό το email ή ο κωδικός δεν είναι σωστός';
      }
    } else if (error[mode].data.description) {
      errorText = error[mode].data.description;
    } else if (error[mode].data.params && error[mode].data.params.length > 0) {
      [errorText] = error[mode].data.params;
    } else if (error[mode].data.message) {
      errorText = error[mode].data.message;
    } else if (error[mode].data.status) {
      if (error[mode].data.status === 401) {
        errorText =
          'Δεν υπάρχει λογαριασμός με αυτό το email ή ο κωδικός δεν είναι σωστός';
      } else if (error[mode].data.status === 400) {
        errorText =
          'Δεν υπάρχει λογαριασμός με αυτό το email ή ο κωδικός δεν είναι σωστός';
      } else {
        errorText = error[mode].data.status;
      }
    }
  } else if (error.message) {
    // Something happened in setting up the request that triggered an error
    errorText = error.message;
  } else {
    errorText = error;
  }

  if (
    errorText === '' ||
    (typeof errorText === 'object' && Object.keys(errorText).length === 0)
  ) {
    return 'Something went wrong!';
  }

  // Capitalize first letter
  errorText = ((str) => {
    if (typeof str !== 'string') return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  })(errorText);

  return errorText;
};

export default instance;
