export default {
  isAuthenticated: (state) => {
    return state.user.token != null;
  },

  user: (state) => {
    return state.user.data;
  },

  userAssessments: (state) => {
    return state.user.assessments;
  },

  assessmentCount: (state) => {
    return state.user.assessmentCount;
  },

  userInitials: (state) => {
    return (
      state.user.data.firstName.toUpperCase().charAt(0) +
      state.user.data.lastName.toUpperCase().charAt(0)
    );
  },

  isAdmin: (state) => {
    return state.user.data.roles.some((role) => role.name === "ADMIN");
  },

  params: (state) => {
    return state.params;
  },

  filters: (state) => {
    return state.filters;
  },

  show: (state) => {
    return state.show;
  },
};
