<template>
  <v-app-bar flat class="d-flex">
    <div class="logo">
      <img :src="require('../../assets/img/logo.svg')" alt="" srcset="" />
    </div>
    <div class="menu hidden-sm-and-down">
      <span
        v-for="menuItem in menuItems"
        :key="menuItem.id"
        @click="goToSection(menuItem.reference)"
      >
        {{ menuItem.name }}
      </span>
    </div>
    <div class="header-button-container">
      <v-btn
        color="secondary"
        variant="flat"
        class="text-none"
        @click="goToStart"
        >Κάντε την αξιολόγηση</v-btn
      >
    </div>
  </v-app-bar>
</template>

<script>
import menuItems from "@/config/menuItems";
export default {
  name: "HeaderBar",
  computed: {
    menuItems() {
      return menuItems;
    },
  },
  methods: {
    goToStart() {
      this.$router.push({ path: "/start" });
    },
    goToSection(ref) {
      this.emitter.emit("scrollToSection", ref);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-toolbar {
  padding: 0 24px;
  box-shadow: 0px 4px 44px 3px rgba(2, 19, 66, 0.06);
}

.menu {
  padding: 0 50px;
  span {
    text-decoration: none;
    padding: 0 10px;
    cursor: pointer;
  }
}

.header-button-container {
  margin-left: auto;
  .v-btn {
    height: 40px;
  }
}
</style>