export default {
  user: {
    token: null,
    data: {},
    assessments: [],
    assessmentCount: {
      total: 0,
      shortAssessments: 0,
      longAssessments: 0,
    },
  },
  params: {
    pagination: {
      size: 12,
      page: 1,
      sort: "createdDate,DESC",
      length: 0,
    },
    filters: {
      status: "all",
      type: "ALL",
      industryId: null,
      keyword: null,
      dateFrom: null,
      dateTo: null,
    },
  },
  filters: {
    questionnaire: 0,
    digitalClass: 0,
    companySize: 0,
    model: 0,
    criteria: 0,
    industries: 0,
    functions: 0,
  },
  show: "grid",
};
