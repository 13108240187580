export default {
  updateUserData: (state, payload) => {
    state.user.data = payload;
  },
  updateToken: (state, payload) => {
    state.user.token = payload;
  },
  logout: (state, payload) => {
    state.user = {
      token: null,
      data: {},
    };
  },
  setUserAssessments: (state, payload) => {
    state.user.assessments = payload;
  },
  setParams: (state, payload) => {
    state.params = payload;
  },
  setParamSize: (state, payload) => {
    state.params.pagination.size = payload;
  },
  setParamPage: (state, payload) => {
    state.params.pagination.page = payload;
  },
  setParamKeyword: (state, payload) => {
    state.params.filters.keyword = payload;
  },
  setParamSort: (state, payload) => {
    state.params.pagination.sort = payload;
  },
  setShow: (state, payload) => {
    state.show = payload;
  },
  setStatus: (state, payload) => {
    state.params.filters.status = payload;
  },
  setType: (state, payload) => {
    state.params.filters.type = payload;
  },
  setAssessmentCount: (state, payload) => {
    state.user.assessmentCount = payload;
  },
  setPaginationLength: (state, payload) => {
    state.params.pagination.length = payload;
  },
  setIndustry: (state, payload) => {
    state.params.filters.industryId = payload;
  },
  setDateFrom: (state, payload) => {
    state.params.filters.dateFrom = payload;
  },
  setDateTo: (state, payload) => {
    state.params.filters.dateTo = payload;
  },
};
