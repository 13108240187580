<template>
  <v-app :class="{ mobile: isMobile }">
    <Header v-if="routeName != null && landingLayout" />
    <v-main v-if="routeName != null && landingLayout">
      <v-container fluid class="pa-0">
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-main v-if="routeName != null && startLayout">
      <v-container fluid class="pa-0 d-flex h-100">
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-main v-if="routeName != null && loginRegisterLayout">
      <v-container fluid class="pa-0 d-flex h-100">
        <Sidebar />
        <div class="page d-flex w-100 lines-bg align-center justify-center">
          <router-view></router-view>
        </div>
      </v-container>
    </v-main>
    <v-main v-if="routeName != null && dashboardLayout">
      <v-container fluid class="pa-0 d-flex h-100">
        <Sidebar />
        <div class="page d-flex w-100 dashboard-layout">
          <router-view></router-view>
        </div>
        <Report />
        <Resource />
      </v-container>
    </v-main>
    <v-main v-if="routeName != null && completedLayout">
      <v-container fluid class="pa-0 d-flex h-100">
        <Sidebar :isCompleted="true" />
        <div class="page d-flex w-100">
          <router-view></router-view>
        </div>
      </v-container>
    </v-main>
    <v-main v-if="routeName != null && questionsLayout">
      <v-container fluid class="pa-0 d-flex h-100">
        <Sidebar :isQuestion="true" />
        <div class="page d-flex w-100">
          <router-view></router-view>
        </div>
      </v-container>
    </v-main>
    <notifications />
  </v-app>
</template>

<script>
import Header from "./components/Header/Header.vue";
import Sidebar from "./components/Sidebar/Sidebar.vue";
import Report from "./components/User/Dashboard/Report/Report.vue";
import Resource from "./components/User/Dashboard/Resource/Resource.vue";
import "./assets/scss/main.scss";
export default {
  name: "App",

  components: {
    Header,
    Sidebar,
    Report,
    Resource
  },

  data: () => ({
    routeName: null,
  }),
  watch: {
    $route(nv) {
      this.routeName = nv.name;
    },
  },
  computed: {
    landingLayout() {
      return this.routeName === "home" || this.routeName === "home ";
    },
    startLayout() {
      return this.routeName === "start";
    },
    loginRegisterLayout() {
      return (
        this.routeName === "login" ||
        this.routeName === "register" ||
        this.routeName === "assessment"
      );
    },
    questionsLayout() {
      return this.routeName === "questions";
    },
    completedLayout() {
      return this.routeName === "complete";
    },
    dashboardLayout() {
      return (
        !this.landingLayout &&
        !this.startLayout &&
        !this.loginRegisterLayout &&
        !this.questionsLayout &&
        !this.completedLayout
      );
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
  mounted() {
    if (this.$route.name) {
      this.routeName = this.$route.name;
    }
  },
};
</script>

<style lang="scss" scoped>
.page {
  padding: 15px 10px;
}

.page.dashboard-layout {
  padding: 0;
}
</style>
