<template>
  <v-navigation-drawer
    v-model="showDialog"
    location="right"
    temporary
    width="775"
    class="resource-drawer"
  >
    <div class="close-btn">
      <v-btn
        color="primary"
        variant="plain"
        icon="mdi-close"
        size="small"
        @click="showDialog = false"
      >
      </v-btn>
    </div>
    <div class="content">
      <h1>{{resource.name}}</h1>
      <div class="digital-class">
        <img v-if="resource.id != undefined" :src="require(`../../../../assets/img/eurobank/${resource.id}.jpg`)" alt="" />
      </div>
      <div>
        {{ resource.description }}
      </div>
      <div class="link-container">
        <a :href="resource.link" target="_blank">Θέλω να παρακολουθήσω</a>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'UserResource',
  data() {
    return {
      showDialog: false,
      resource: {},
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  created() {
    this.emitter.on('openResource', (data) => {
      this.showDialog = true;
      this.resource = data;
    });
  },
};
</script>

<style lang="scss" scoped>
.resource-drawer {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  color: rgba(var(--v-theme-primary), 1);
  .close-btn {
    position: absolute;
    right: 15px;
    top: 20px;
  }
  .content {
    padding: 30px;
    h1 {
      margin-bottom: 30px;
    }
    .link-container {
      padding-top: 20px;
      a {
        font-size: 20px;
        font-weight: 600;
        color: #0050B5;
        text-decoration: none;
      }
    }
    .digital-class {
      margin-bottom: 20px;
      img{
        width: 100%;
      }
      .rank {
        font-weight: bold;
      }
    }
    ul {
      margin-bottom: 30px;
    }
    .criteria {
      .title {
        font-weight: bold;
        margin-bottom: 10px;
      }
      .scores {
        row-gap: 10px;
        .score {
          min-height: 50px;
          .name {
            flex: 0 0 24%;
            font-size: 14px;
            padding-right: 5px;
          }
          .progress {
            flex: 0 0 70%;
          }
          .value {
            flex: 0 0 6%;
          }
        }
      }
    }
  }
}
</style>
